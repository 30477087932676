import React, { useState } from "react";
import classes from "./CareersForm.module.css";

import { TextField } from "@mui/material";

import Button from "../../UI/Button/Button";
import axios from "axios";

const CareersForm = () => {
  // const first = useRef()
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [major, setMajor] = useState("");

  const [note, setNote] = useState("");
  const [noteColor, setNoteColor] = useState("green");
  const [inputColor, setInputColor] = useState("black");

  const [fError, setFError] = useState(false);
  const [lError, setLError] = useState(false);
  const [eError, setEError] = useState(false);
  const [pError, setPError] = useState(false);
  const [mError, setMError] = useState(false);

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");

  const saveFile = (e) => {
    // console.log(e);
    if (e.target.files.length===0) {
      setFile("")
      setFileName("")
      return;
    }
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const submit = () => {
    if (first === "") {
      setFError(true);
    }
    if (last === "") {
      setLError(true);
    }
    if (email === "") {
      setEError(true);
    }
    if (phone === "") {
      setPError(true);
    }
    if (major === "") {
      setMError(true);
    }
    if (fileName === "") {
      console.log(fileName);
      setInputColor("red");
    }

    if (!(first === "")) {
      setFError(false);
    }
    if (!(last === "")) {
      setLError(false);
    }
    if (!(email === "")) {
      setEError(false);
    }
    if (!(phone === "")) {
      setPError(false);
    }
    if (!(major === "")) {
      setMError(false);
    }
    if (!(fileName === "")) {
      setInputColor("black");
    }
    if (
      !(first === "") &&
      !(last === "") &&
      !(email === "") &&
      !(phone === "") &&
      !(major === "") &&
      !(fileName === "")
    ) {
      const data = {
        first,
        last,
        email,
        phone,
        major,
      };
      // console.log(first);
      // console.log(last);
      // console.log(email);
      // console.log(phone);
      // console.log(major);
      // console.log(data);

      const fileData = new FormData();
      fileData.append("file", file);
      fileData.append("fileName", fileName);
      fileData.append("first", first);
      fileData.append("last", last);
      fileData.append("email", email);
      fileData.append("phone", phone);
      fileData.append("major", major);

      console.log(fileName);
      const ext = getExtension(fileName);
      console.log("extension", ext);
      console.log(fileData.get("file"));

      setNoteColor("blue");
      setNote("Uploading...");
      axios
        .post(process.env.REACT_APP_API_URL + "/careers", fileData)
        .then((response) => {
          console.log(response);
          setFirst("");
          setLast("");
          setEmail("");
          setPhone("");
          setMajor("");

          setNoteColor("green");
          setNote("Sent!");
        })
        .catch((error) => {
          setNote("ERROR: TRY AGAIN LATER");
          setNoteColor("red");
          console.log(error);
        });
    }
  };

  return (
    <div className={classes.container}>
      <h2 className={classes.label}>Name</h2>
      <div className={classes.row}>
        <TextField
          label="First"
          variant="outlined"
          fullWidth
          value={first}
          error={fError}
          onChange={(newValue) => setFirst(newValue.target.value)}
        />
        <TextField
          label="Last"
          variant="outlined"
          fullWidth
          value={last}
          error={lError}
          onChange={(newValue) => setLast(newValue.target.value)}
        />
      </div>
      <h2 className={classes.label}>Email</h2>
      <TextField
        label="example@example.com"
        variant="outlined"
        fullWidth
        value={email}
        error={eError}
        onChange={(newValue) => setEmail(newValue.target.value)}
      />
      <h2 className={classes.label}>Phone</h2>
      <TextField
        label="Phone"
        type="number"
        variant="outlined"
        fullWidth
        value={phone}
        error={pError}
        onChange={(newValue) => setPhone(newValue.target.value)}
      />
      <h2 className={classes.label}>Major</h2>
      <TextField
        variant="outlined"
        fullWidth
        value={major}
        error={mError}
        onChange={(newValue) => setMajor(newValue.target.value)}
      />
      <h2 className={classes.label}>Your CV</h2>
      <input
        type="file"
        name="filename"
        accept=".pdf"
        onChange={saveFile}
        className={classes.fileSelect}
        style={{ color: inputColor }}
      />

      <Button className={classes.btn} onClick={submit}>
        Submit
      </Button>
      <h4 style={{ color: noteColor }}>{note}</h4>
    </div>
  );
};

export default CareersForm;
