import React from 'react'

import Header from '../Header/Header'
import FooterSection from '../FooterSection/FooterSection'
import CareersSection from '../CareersSection/CareersSection'

const CareersPage = () => {
  return (
    <div style={{width: "100%"}}>
        <Header active="Careers" />
        <CareersSection />
        <FooterSection />
    </div>
  )
}

export default CareersPage
