import React from 'react';
import Header from '../../Header/Header';
import ServicesPageMultiTab from '../../ServicesPageMultiTab/ServicesPageMultiTab';
import MonitoringEvaluationSection from '../../MonitoringEvaluationSection/MonitoringEvaluationSection';
import FooterSection from '../../FooterSection/FooterSection';

const MonitoringEvaluationPage = () => {

   
  return (
    <div style={{width: "100%" }}>
      <Header active="Services" />
      <ServicesPageMultiTab active="one"/>
      <MonitoringEvaluationSection />
      <FooterSection />
    </div>
  )
}

export default MonitoringEvaluationPage
