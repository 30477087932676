import React from 'react'
import classes from "./ContactUsSection.module.css"
import ContactUsForm from './ContactUsForm/ContactUsForm'
import ContactUsMap from './ContactUsMap/ContactUsMap'

const ContactUsSection = () => {
  return (
    <div className={classes.container}>
      <ContactUsForm />
      <ContactUsMap />
    </div>
  )
}

export default ContactUsSection
