import React from "react";
import classes from "./GalleryCarouselSection.module.css";

import ImageVerticalCarousel from "./ImageVerticalCarousel/ImageVerticalCarousel";
import Button from "../../UI/Button/Button";

const GalleryCarouselSection = () => {
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <div className={classes.titleContainer}>
          <h1>
            <i>GHTE Gallery</i>
          </h1>
          <p>
            <i>View our images and videos in the Collections Below:</i>
          </p>
            {/* <Button className={classes.btn}>Checkout Collections</Button> */}
        </div>
      </div>
      <div className={classes.carousel}>
        <ImageVerticalCarousel carouselSpeed={2}>
          <img src="/img/carousel/1.webp" />
          <img src="/img/carousel/2.webp" />
          <img src="/img/carousel/3.jpg" />
          <img src="/img/carousel/4.jpg" />
          <img src="/img/carousel/5.jpg" />
        </ImageVerticalCarousel>
        <ImageVerticalCarousel carouselSpeed={1}>
          <img src="/img/carousel/6.jpg" />
          <img src="/img/carousel/7.jpg" />
          <img src="/img/carousel/8.jpg" />
          <img src="/img/carousel/9.jpg" />
          <img src="/img/carousel/10.jpg" />
        </ImageVerticalCarousel>
        <ImageVerticalCarousel carouselSpeed={1.6}>
          <img src="/img/carousel/11.jpg" />
          <img src="/img/carousel/12.jpg" />
          <img src="/img/carousel/13.jpg" />
          <img src="/img/carousel/14.jpg" />
          <img src="/img/carousel/15.jpg" />
        </ImageVerticalCarousel>
      </div>
    </div>
  );
};
export default GalleryCarouselSection;
