import React from "react";

import Header from "../Header/Header";
import FooterSection from "../FooterSection/FooterSection";
import GalleryCarouselSection from "../GalleryComponents/GalleryCarouselSection/GalleryCarouselSection";
import GalleryCollectionsSection from "../GalleryComponents/GalleryCollectionsSection/GalleryCollectionsSection";

const GalleryPage = () => {
  return (
    <div style={{ width: "100%" }}>
      <Header active="Gallery" />
      <GalleryCarouselSection />
      <GalleryCollectionsSection />
      <FooterSection />
    </div>
  );
};

export default GalleryPage;
