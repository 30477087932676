import React from "react";
import Header from "../../Header/Header";
import ServicesPageMultiTab from "../../ServicesPageMultiTab/ServicesPageMultiTab";
import FooterSection from "../../FooterSection/FooterSection";
import ProjectManagementSection from "../../ProjectManagementSection/ProjectManagementSection"

const ProjectManagementPage = () => {
  return <div style={{ width: "100%" }}>
    <Header active="Services" />
      <ServicesPageMultiTab active="two"/>
      <ProjectManagementSection />
      <FooterSection />
  </div>;
};

export default ProjectManagementPage;
