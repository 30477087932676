import React from "react";
import classes from "./Landing.module.css"
import LandingGraphic from "./LandingGraphic/LandingGraphic";
import LandingMotto from "./LandingMotto/LandingMotto";

const Landing = () =>{

    return <div className={classes.container}>
        <LandingGraphic />
        <LandingMotto />
    </div>
}
export default Landing