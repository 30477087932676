import React, { useEffect, useRef } from "react";
import classes from "./ProjectManagementSection.module.css";

import { gsap } from "gsap";
import Space from "../UI/Space/Space";

const BoxListItem = (props) => {
  const { children, className } = props;

  return (
    <div className={classes.box + " " + className}>
      <li>{children}</li>
    </div>
  );
};

const MonitoringEvaluationSection = () => {
  const bigCircle = useRef();
  const circles = useRef();
  const circle = useRef();

  const topList = useRef();

  useEffect(() => {
    const circleAnimation = {
      duration: 2,
      opacity: 0,
      yPercent: 100,
      stagger: 0.3,
      ease: "back",
    };
    const circleIdle = {
      delay: 3.2,
      duration: 3,
      y: -20,
      stagger: 2,
      repeat: -1,
      yoyo: true,
      ease: "easeInOut",
    };

    const topListAnimation = {
      duration: 1,
      opacity: 0,
      xPercent: 100,
      // stagger:0.1,
      ease: "back",
    };

    gsap.timeline().from(bigCircle.current, circleAnimation);
    // gsap.timeline().from(circles.current.children, circleAnimation);

    gsap.timeline().from(topList.current.children, topListAnimation);
  }, []);

  return (
    <div className={classes.container}>
      <h1>Project Management</h1>
      {/* ####################################################################### */}
      <Space height="25px" />
      <div className={classes.row + " " + classes.section1}>
        <div className={classes.col + " " + classes.relative}>
          <p className={classes.bigCircle} ref={bigCircle}>
            We support our partners in identifying business opportunities,
            partnership opportunities and writing proposals. Services provided
            by GHTE support the business planning process in any of its stages.
          </p>
          {/* <div className={classes.row + " " + classes.position} ref={circles}>
            <div className={classes.circle} ref={circle}>
              <img src="/img/icons/svg/shield.svg" />
              <h3>Himaya</h3>
            </div>
          </div> */}
        </div>
        <div className={classes.col} ref={topList}>
          <div className={classes.box}>
            <h3>Our services:</h3>
          </div>
          <BoxListItem className={classes.item}>Gap analysis</BoxListItem>
          <BoxListItem className={classes.item}>SWOT analysis</BoxListItem>
          <BoxListItem className={classes.item}>Needs assessments</BoxListItem>
          <BoxListItem className={classes.item}>
            Business plan for healthcare organizations
          </BoxListItem>
          <BoxListItem className={classes.item}>
            Health project management
          </BoxListItem>
          <BoxListItem className={classes.item}>
            Healthcare management
          </BoxListItem>
          {/* <div className={classes.smallBoxes}>
            <div className={classes.smallBox}>
              <img src="/img/icons/svg/shield.svg" />
              <h4>Himaya</h4>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MonitoringEvaluationSection;
