import React, {useEffect,useRef} from "react";
import classes from "./LandingGraphic.module.css"

import { gsap } from "gsap";


const LandingGraphic = () =>{
    const container = useRef()

    // useEffect(()=>{
    //     gsap.timeline().from(container.current, {
    //         delay:0.5,
    //         duration:1,
    //         opacity:0,
    //         xPercent:-100,
    //         yPercent: 30,
    //         scale:0.5,
    //     })

    //     // gsap.from(container.current.children, {
    //     //     delay:0.5,
    //     //     duration:1.5,
    //     //     width:"50%",
    //     //     height:"50%",
    //     // })
    // },[])

    return <div className={classes.container} ref={container}>
        <img src="/img/landing.webp" className={classes.img} />
        <div className={classes.shadow}></div>
    </div>
}
export default LandingGraphic