import React from 'react'

const Space = (props) => {
    const {height} = props
  return (
    <div style={{width: "100%", height: height}}>
      
    </div>
  )
}

export default Space
