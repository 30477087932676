import React, { useRef, useLayoutEffect } from "react";
import classes from "./LandingMotto.module.css";

import { gsap } from "gsap";

const LandingMotto = () => {
  const first = useRef();
  const second = useRef();
  const third = useRef();
  const one = useRef();
  const two = useRef();

  useLayoutEffect(() => {
    gsap.from(one.current, {
      delay: 0,
      xPercent: -50,
      duration: 2,
      ease: "power4.out",
    });
    gsap.from(two.current, {
      delay: 0,
      xPercent: 20,
      duration: 3,
      ease: "power4.out",
    });

    gsap
      .timeline()
      .from(first.current, {
        delay: 1,
        opacity: 0,
        duration: 1,
      })
      .from(second.current, {
        delay: 0.5,
        opacity: 0,
        duration: 1,
      })
      .from(third.current, {
        delay: 0.5,
        opacity: 0,
        duration: 1,
      });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.one} ref={one}></div>
      <div className={classes.two} ref={two}></div>
      <p className={classes.motto} ref={first}>
        Evaluators
      </p>
      <p className={classes.motto} ref={second}>
        IT Specialists
      </p>
      <p className={classes.motto} ref={third}>
        Trainers
      </p>
    </div>
  );
};
export default LandingMotto;
