import React from "react";
import classes from "./ServicesSection.module.css";

import Button from "../UI/Button/Button";
import { Link } from "react-router-dom";

const ServicesSection = () => {
  return (
    <div className={classes.container}>
      <h1>Our Services</h1>
      <div className={classes.menu}>
        <Link to="/services/accountabilitylearning">
          <Button className={classes.btn}>
            <img src="/img/icons/svg/monitoring.svg" />
            <h3>
              Monitoring Evaluation
              <br /> Accountability Learning
            </h3>
          </Button>
        </Link>

        <Link to="/services/projectmanagement">
          <Button className={classes.btn}>
            <img src="/img/icons/svg/project.svg" />
            <h3>Project Management</h3>
          </Button>
        </Link>
        <Link to="/services/itconsultancy">
          <Button className={classes.btn}>
            <img src="/img/icons/svg/it.svg" />
            <h3>Information Technology Consultancy </h3>
          </Button>
        </Link>
      </div>
    </div>
  );
};
export default ServicesSection;
