import React, { useState, useRef, useEffect } from "react";
import classes from "./ImageVerticalCarousel.module.css";
import { gsap } from "gsap";

function ImageVerticalCarousel(props) {
  const { children, carouselSpeed } = props;
  const squares = useRef();

  const [pause, setPause] = useState(false);
  let boxes = [];
  //   const boxes = gsap.utils.toArray(".box");

  const setMiddle = (mid) => {
    boxes.forEach((box) => box.classList.remove("middle-item"));
    boxes[mid].classList.add("middle-item");
  };

  const horizontalLoop = (items, config) => {
    items = gsap.utils.toArray(items);
    config = config || {};
    let tl = gsap.timeline({
        repeat: config.repeat,
        paused: config.paused,
        defaults: { ease: "none" },
        onReverseComplete: () =>
          tl.totalTime(tl.rawTime() + tl.duration() * 100),
      }),
      length = items.length,
      startX = items[0].offsetTop,
      times = [],
      widths = [],
      xPercents = [],
      curIndex = 0,
      pixelsPerSecond = (config.speed || 1) * 29,
      snap =
        config.snap === false ? (v) => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
      populateWidths = () =>
        items.forEach((el, i) => {
          widths[i] = parseFloat(gsap.getProperty(el, "height", "px"));
          xPercents[i] = snap(
            (parseFloat(gsap.getProperty(el, "y", "px")) / widths[i]) * 100 +
              gsap.getProperty(el, "yPercent")
          );
        }),
      getTotalWidth = () =>
        items[length - 1].offsetTop +
        (xPercents[length - 1] / 100) * widths[length - 1] -
        startX +
        items[length - 1].offsetHeight *
          gsap.getProperty(items[length - 1], "scaleY") +
        (parseFloat(config.paddingRight) || 0),
      totalWidth,
      curX,
      distanceToStart,
      distanceToLoop,
      item,
      i;
    populateWidths();
    gsap.set(items, {
      // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
      yPercent: (i) => xPercents[i],
    });
    gsap.set(items, { y: 0 });
    totalWidth = getTotalWidth();
    for (i = 0; i < length; i++) {
      item = items[i];
      curX = (xPercents[i] / 100) * widths[i];
      distanceToStart = item.offsetTop + curX - startX;
      distanceToLoop =
        distanceToStart + widths[i] * gsap.getProperty(item, "scaleY");
      tl.to(
        item,
        {
          yPercent: snap(((curX - distanceToLoop) / widths[i]) * 100),
          duration: distanceToLoop / pixelsPerSecond,
        },
        0
      )
        .fromTo(
          item,
          {
            yPercent: snap(
              ((curX - distanceToLoop + totalWidth) / widths[i]) * 100
            ),
          },
          {
            yPercent: xPercents[i],
            duration:
              (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond,
            immediateRender: false,
          },
          distanceToLoop / pixelsPerSecond
        )
        .add("label" + i, distanceToStart / pixelsPerSecond);
      times[i] = distanceToStart / pixelsPerSecond;
    }
    function toIndex(index, vars) {
      vars = vars || {};
      Math.abs(index - curIndex) > length / 2 &&
        (index += index > curIndex ? -length : length); // always go in the shortest direction
      let newIndex = gsap.utils.wrap(0, length, index),
        time = times[newIndex];
      if (time > tl.time() !== index > curIndex) {
        // if we're wrapping the timeline's playhead, make the proper adjustments
        vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
        time += tl.duration() * (index > curIndex ? 1 : -1);
      }
      curIndex = newIndex;
      vars.overwrite = true;
      return tl.tweenTo(time, vars);
    }
    tl.next = (vars) => {
      toIndex(curIndex + 1, vars) && setMiddle(tl.current() + 2);
    };
    tl.previous = (vars) => {
      toIndex(curIndex - 1, vars) && setMiddle(tl.current() + 2);
    };
    tl.current = () => curIndex;
    tl.toIndex = (index, vars) => toIndex(index, vars);
    tl.updateIndex = () =>
      (curIndex = Math.round(tl.progress() * items.length));
    tl.times = times;
    tl.progress(1, true).progress(0, true); // pre-render for performance
    if (config.reversed) {
      tl.vars.onReverseComplete();
      tl.reverse();
    }

    return tl;
  };

  let startAnimation;
  let pauseAnimation;
  let loop;

  useEffect(() => {
    // const boxes = squares.current.children;
    boxes = squares.current.children;
    loop = horizontalLoop(boxes, {
      paused: pause,
      speed: carouselSpeed || 1,
      repeat: -1,
      paddingRight: "20px",
    });

    // boxes.forEach((box, i) =>
    //   box.addEventListener("click", () =>
    //     loop.toIndex(i, { duration: 0.8, ease: "power1.inOut" })
    //   )
    // );
  }, []);

  //   pauseAnimation = (e) => {
  //     loop.pause();
  //     gsap.set(e.target, { css: { zIndex: 1000 } });
  //     gsap.to(e.target, {
  //       duration: 0.5,
  //       scale: 1.5,
  //       // zIndex: 1000
  //     });

  //   };

  //   startAnimation = (e) => {

  //     gsap.to(e.target, {
  //       duration: 0.5,
  //       scale: 1,
  //     });
  //     gsap.set(e.target, { css: { zIndex: 1 } });
  //     loop.resume();
  //   };

  return (
    <div className={classes.wrapper} ref={squares}>
      {children.map((box, index) => {
        return (
          <div
            key={index}
            className={classes.box}
            // onMouseOver={pauseAnimation}
            // onMouseOut={startAnimation}
          >
            {box}
          </div>
        );
      })}
    </div>
  );
}

export default ImageVerticalCarousel;
