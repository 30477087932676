import React from "react";
import classes from "./GalleryCollectionView.module.css";

const GalleryCollectionView = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.imgCard}>
        <img src="/img/carousel/1.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/2.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/3.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/4.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/landing.png" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/aboutus.jpeg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/7.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/8.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/9.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/10.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/11.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/12.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/13.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/14.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/15.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/1.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/2.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/3.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/4.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/landing.png" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/aboutus.jpeg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/7.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/8.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/9.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/10.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/11.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/12.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/13.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/14.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/15.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/1.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/2.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/3.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/4.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/landing.png" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/aboutus.jpeg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/7.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/8.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/9.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/10.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/11.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/12.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/13.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/14.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/15.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/1.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/2.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/3.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/4.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/landing.png" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/aboutus.jpeg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/7.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/8.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/9.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/10.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/11.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/12.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/13.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/14.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/15.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/1.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/2.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/3.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/4.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/landing.png" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/aboutus.jpeg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/7.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/8.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/9.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/10.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/11.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/12.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/13.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/14.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/15.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/1.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/2.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/3.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/4.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/landing.png" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/aboutus.jpeg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/7.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/8.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/9.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/10.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/11.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/12.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/13.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/14.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/15.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/1.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/2.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/3.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/4.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/landing.png" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/aboutus.jpeg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/7.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/8.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/9.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/10.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/11.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/12.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/13.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/14.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/15.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/1.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/2.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/3.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/4.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/landing.png" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/aboutus.jpeg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/7.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/8.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/9.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/10.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/11.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/12.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/13.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/14.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/15.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/1.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/2.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/3.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/4.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/landing.png" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/aboutus.jpeg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/7.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/8.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/9.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/10.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/11.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/12.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/13.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/14.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/15.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/1.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/2.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/3.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/4.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/landing.png" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/aboutus.jpeg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/7.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/8.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/9.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/10.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/11.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/12.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/13.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/14.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/15.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/1.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/2.webp" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/3.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/4.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/landing.png" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/aboutus.jpeg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/7.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/8.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/9.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/10.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/11.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/12.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/13.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/14.jpg" />
      </div>
      <div className={classes.imgCard}>
        <img src="/img/carousel/15.jpg" />
      </div>
    </div>
  );
};

export default GalleryCollectionView;
