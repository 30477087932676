import React, { useEffect, useRef } from "react";
import classes from "./OurStructureSection.module.css";

import FlowDiagram from "./FlowDiagram/FlowDiagram";

import { gsap } from "gsap";

const OurStructureSection = () => {
  const container = useRef();

  useEffect(() => {
    gsap.from(container.current, {
      duration: 1,
      opacity: 0,
      yPercent:50, 
      scrollTrigger: {
        trigger: container.current,
        toggleActions: "restart resume resume reverse",
        // scrub: true,
        start: () => "top bottom",
        end: () => "+=300px",
        // markers: true,
      },
    });
  }, []);

  return (
    <div className={classes.container} ref={container}>
      <h1>Our Structure</h1>
      <FlowDiagram />
    </div>
  );
};

export default OurStructureSection;
