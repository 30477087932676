import React from "react";
import classes from "./CEOProfile.module.css";

const CEOProfile = (props) => {
  const { className } = props;

  return (
    <div className={classes.container + " " + className}>
      <img src="/img/ceosmall.webp" />
    </div>
  );
};
export default CEOProfile;
