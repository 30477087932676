import React, { useEffect, useState } from "react";
import classes from "./GalleryCollectionsSection.module.css";

import axios from "axios";
import Button from "../../UI/Button/Button";
import ImageComponent from "./ImageComponent/ImageComponent"
import VideoComponent from "./VideoComponent/VideoComponent"

const CollectionCard = (props) => {
  const { collection, setIsSelectedCollection } = props;
  const { id, title } = collection;

  return (
    <Button className={classes.collectionCard} onClick={()=>{setIsSelectedCollection(id)}}>
      <img
        src={process.env.REACT_APP_API_URL + `/collections/thumbnail/${id}`}
        alt="img"
      />
      <h2>{title}</h2>
    </Button>
  );
};

const GalleryCollectionsSection = () => {
  const [collections, setCollections] = useState([]);
  const [selectedCollection, setIsSelectedCollection] = useState(null);

  const [images, setImages] = useState([])
  const [videos, setVideos] = useState([])

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + `/collections/`)
      .then((response) => {
        setCollections(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(()=>{
    if(selectedCollection){
      axios
        .get(process.env.REACT_APP_API_URL + `/images/${selectedCollection}`)
        .then((response) => {
          // console.log("response", response.data);
          setImages(response.data);
        })
        .catch((error) => {
          console.error("error", error);
        });

      axios
        .get(process.env.REACT_APP_API_URL + `/videos/${selectedCollection}`)
        .then((response) => {
          // console.log("response", response.data);
          setVideos(response.data);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  },[selectedCollection])

  return (
    <div className={classes.container}>
      <h1>Collections</h1>
      <div className={classes.collections}>
        {collections
          .slice(0)
          .reverse()
          .map((collection) => {
            return (
              <CollectionCard
                key={collection.id}
                collection={collection}
                setIsSelectedCollection={setIsSelectedCollection}
                img="/img/carousel/1.webp"
                title="Collection 1"
              />
            );
          })}
      </div>
      <div className={classes.list}>
        {images
          .slice(0)
          //   .reverse()
          .map((image) => {
            return (
              <ImageComponent
                key={image.id}
                id={image.id}
              />
            );
          })}
      </div>
      <div className={classes.list}>
        {videos
          .slice(0)
          //   .reverse()
          .map((video) => {
            return (
              <VideoComponent
                key={video.id}
                id={video.id}
              />
            );
          })}
      </div>
    </div>
  );
};
export default GalleryCollectionsSection;
