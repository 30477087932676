import React, { useEffect, useState, useRef } from "react";
import classes from "./VideoComponent.module.css";

import axios from "axios";

import Modal from "../../../UI/Modal/Modal";

const VideoComponent = (props) => {
  const { id } = props;

  const [thumbnail, setThumbnail] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false)

  const videoRef = useRef(null)

  const getThumbnail = () => {
    axios
      .get(process.env.REACT_APP_API_URL + `/videos/thumbnail/${id}`)
      .then((response) => {
        setThumbnail(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getThumbnail();
  }, []);


  const cancel = ()=>{
    setIsModalVisible(false)
  }

  

  return (
    <div className={classes.container}>
        {isModalVisible && <Modal cancel={cancel}>
            <video ref={videoRef}  controls autoPlay>
                <source src={process.env.REACT_APP_API_URL + `/videos/video/${id}`} type="video/mp4"></source>
            </video>
        </Modal>}
      {thumbnail && (
        <img
          src={process.env.REACT_APP_API_URL + `/videos/thumbnail/${id}`}
          alt="img"
          className={classes.img}
          onClick={()=>{setIsModalVisible(true)}}
        />
      )}
      {thumbnail && (
        <img
          className={classes.play}
          //   onClick={deleteImage}
          src="/img/icons/play.png"
          onClick={()=>{setIsModalVisible(true)}}
        />
      )}
    </div>
  );
};

export default VideoComponent;
