import React, { useEffect, useRef } from "react";
import classes from "./ITConsultancySection.module.css";

import { gsap } from "gsap";

const BoxListItem = (props) => {
  const { children, className } = props;

  return (
    <div className={classes.box + " " + className}>
      <li>{children}</li>
    </div>
  );
};

const ITConsultancySection = () => {
  const bigCircle = useRef();
  const circles = useRef();
  const circle = useRef();

  const topList = useRef();

  useEffect(() => {
    const circleAnimation = {
      duration: 2,
      opacity: 0,
      yPercent: 100,
      stagger: 0.3,
      ease: "back",
    };
    const circleIdle = {
      delay: 3.2,
      duration: 3,
      y: -20,
      stagger: 2,
      repeat: -1,
      yoyo: true,
      ease: "easeInOut",
    };

    const topListAnimation = {
      duration: 1,
      opacity: 0,
      xPercent: 100,
      // stagger:0.1,
      ease: "back",
    };

    gsap.timeline().from(bigCircle.current, circleAnimation);
    // gsap.timeline().from(circles.current.children, circleAnimation);

    gsap.timeline().from(topList.current.children, topListAnimation);
  }, []);

  return (
    <div className={classes.container}>
      <h1>Information Technology Consultancy</h1>
      {/* ####################################################################### */}

      <div className={classes.row + " " + classes.section1}>
        <div className={classes.col + " " + classes.relative}>
          <p className={classes.bigCircle} ref={bigCircle}>
            We support our partners from building their skills and capacities in
            web development, data security, integrating information systems in
            workplaces, building databases and developing software. Services
            provided by the IT unit at GHTE support institutions in any phase of
            their work process.
          </p>
          {/* <div className={classes.row + " " + classes.position} ref={circles}>
            <div className={classes.circle} ref={circle}>
              <img src="/img/icons/svg/shield.svg" />
              <h3>Himaya</h3>
            </div>
          </div> */}
        </div>
        <div className={classes.col} ref={topList}>
          <div className={classes.box}>
            <h3>Our services:</h3>
          </div>
          <BoxListItem className={classes.item}>Web development </BoxListItem>
          <BoxListItem className={classes.item}>Data security</BoxListItem>
          <BoxListItem className={classes.item}>Capacity building (cyber security, Microsoft office, SPSS, ….) </BoxListItem>
          <BoxListItem className={classes.item}>
            Database development and maintenance 
          </BoxListItem>
          <BoxListItem className={classes.item}>
            Development of information systems (Health Information Systems among others) 
          </BoxListItem>
          <BoxListItem className={classes.item}>
            Software development 
          </BoxListItem>
          <BoxListItem className={classes.item}>
            Development of Mobile applications (android and IOS)  
          </BoxListItem>
          {/* <div className={classes.smallBoxes}>
            <div className={classes.smallBox}>
              <img src="/img/icons/svg/shield.svg" />
              <h4>Himaya</h4>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ITConsultancySection;
