import React from "react";

import Header from "../Header/Header";
import FooterSection from "../FooterSection/FooterSection";
import PublicationsListSection from "../PublicationsListSection/PublicationsListSection";

const GalleryPage = () => {
  return (
    <div style={{ width: "100%" }}>
      <Header active="Publications" />
      <PublicationsListSection />
      <FooterSection />
    </div>
  );
};

export default GalleryPage;
