import React, { useState } from "react";
import classes from "./ContactUsForm.module.css";

import { TextField } from "@mui/material";

import Button from "../../UI/Button/Button";
import axios from "axios";

const ContactUsForm = () => {
  const [first, setFirst] = useState("");
  const [last, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [note, setNote] = useState("");
  const [noteColor, setNoteColor] = useState("green");

  const [fError, setFError] = useState(false);
  const [lError, setLError] = useState(false);
  const [eError, setEError] = useState(false);
  const [mError, setMError] = useState(false);

  const submit = () => {
    if (first === "") {
      setFError(true);
    }
    if (last === "") {
      setLError(true);
    }
    if (email === "") {
      setEError(true);
    }
    if (message === "") {
      setMError(true);
    }

    if (!(first === "")) {
      setFError(false);
    }
    if (!(last === "")) {
      setLError(false);
    }
    if (!(email === "")) {
      setEError(false);
    }
    if (!(message === "")) {
      setMError(false);
    }

    if (
      !(first === "") &&
      !(last === "") &&
      !(email === "") &&
      !(message === "")
    ) {
      const data = {
        first,
        last,
        email,
        message,
      };
      axios
        .post(process.env.REACT_APP_API_URL + "/contacts", {
          data,
        })
        .then((response) => {
          // console.log(response);
          setFirst("");
          setLast("");
          setEmail("");
          setMessage("");

          setNoteColor("green")
          setNote("Sent!")
        })
        .catch((error) => {
          setFError(true);
          setLError(true);
          setEError(true);
          setMError(true);

          setNote("ERROR: TRY AGAIN LATER");
          setNoteColor("red");
          console.log(error);
        });
    }

    // console.log(first);
    // console.log(last);
    // console.log(email);
    // console.log(phone);
    // console.log(major);
    // console.log(data);
  };

  return (
    <div className={classes.container}>
      <h2 className={classes.label}>Name</h2>
      <div className={classes.row}>
        <TextField
          label="First"
          variant="outlined"
          fullWidth
          value={first}
          error={fError}
          onChange={(newValue) => setFirst(newValue.target.value)}
        />
        <TextField
          label="Last"
          variant="outlined"
          fullWidth
          error={lError}
          value={last}
          onChange={(newValue) => setLast(newValue.target.value)}
        />
      </div>
      <h2 className={classes.label}>Email</h2>
      <TextField
        label="example@example.com"
        variant="outlined"
        fullWidth
        value={email}
        error={eError}
        onChange={(newValue) => setEmail(newValue.target.value)}
      />
      <h2 className={classes.label}>Message</h2>
      <TextField
        label="Message"
        multiline
        rows={10}
        fullWidth
        error={mError}
        value={message}
        onChange={(newValue) => setMessage(newValue.target.value)}
      />
      <Button className={classes.btn} onClick={submit}>
        Submit
      </Button>
      <h4 style={{ color: noteColor }}>{note}</h4>
    </div>
  );
};

export default ContactUsForm;
