import React, { useEffect, useRef } from "react";
import classes from "./PulsingSparkle.module.css";

import { gsap } from "gsap";

const PulsingSparkle = ({ className, delay = 0, duration = 0.2, scale = 1 }) => {
  const container = useRef();

  useEffect(() => {
    gsap
      .timeline({
        delay: delay,
        repeat: -1,
      })
      .to(container.current, {
        scale: scale,
        duration: duration,
        ease: "power4.In",
      })
      .to(container.current, {
        scale: 0,
        duration: duration,
        ease: "power4.Out",
      })
      .to(container.current, {
        scale: 0,
        duration: 2,
      });
  }, []);

  return (
    <div className={classes.container + " " + className} ref={container} style={{scale:"0"}}>
      <svg
        width="55"
        height="61"
        viewBox="0 0 55 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_404_118)">
          <path
            d="M17.3383 18.9223C17.3383 18.9223 25.3377 -22.8486 32.4482 18.9223C32.4482 18.9223 72.4449 26.0323 32.4482 33.1422C32.4482 33.1422 25.3377 75.8019 17.3383 33.1422C-6.65972 28.6985 -4.88208 24.2548 17.3383 18.9223Z"
            fill="url(#paint0_linear_404_118)"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_404_118"
            x="-4"
            y="0.357483"
            width="58.2245"
            height="59.7446"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_404_118"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_404_118"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_404_118"
            x1="25.1123"
            y1="0.357483"
            x2="25.1123"
            y2="52.1021"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FCCE01" />
            <stop offset="1" stop-color="#FCCE01" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};
export default PulsingSparkle;
