import React, { useEffect, useState } from "react";
import classes from "./ImageComponent.module.css";

import axios from "axios";
import Modal from "../../../UI/Modal/Modal";

const ImageComponent = (props) => {
  const { id } = props;

  const [image, setImage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getIImage = () => {
    axios
      .get(process.env.REACT_APP_API_URL + `/images/image/${id}`)
      .then((response) => {
        setImage(response);
        // console.log(btoa(unescape(encodeURIComponent(response.data))))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getIImage();
  }, []);

  const cancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div className={classes.container}>
      {isModalVisible && (
        <Modal cancel={cancel}>
          <img
            src={process.env.REACT_APP_API_URL + `/images/image/${id}`}
            alt="img"
            className={classes.BigImg}
          />
        </Modal>
      )}
      {image && (
        <img
          src={process.env.REACT_APP_API_URL + `/images/image/${id}`}
          alt="img"
          className={classes.img}
          onClick={()=>{setIsModalVisible(true)}}
        />
      )}
    </div>
  );
};

export default ImageComponent;
