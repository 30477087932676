import React, { useEffect, useRef } from "react";
import classes from "./ContactUsMap.module.css";

import { gsap } from "gsap";

const ContactUsMap = () => {
  const map = useRef();
  const info = useRef();

  useEffect(() => {
    gsap
      .timeline()
      .from(info.current.children, {
        duration: 1,
        xPercent: 100,
        opacity: 0,
        stagger: 0.3,
      });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.mapWrapper} ref={map}>
        {/* <iframe title="map" src={props.location}></iframe> */}
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3312.132316720856!2d35.510889744979956!3d33.8862459602497!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f171d3d57dfcf%3A0x87a68f16e00c5822!2sSama%20Beirut!5e0!3m2!1sen!2slb!4v1679957806392!5m2!1sen!2slb"></iframe>
      </div>
      <div className={classes.info} ref={info}>
        <h3>Petro Trad Street Sama Beirut Building 11th Floor </h3>
        <div className={classes.item}>
          <img src="/img/icons/svg/location.svg" alt={"location"} />
          <p>Ashrafieh, Beirut - Lebanon</p>
        </div>
        <div className={classes.item}>
          <img src="/img/icons/svg/phone.svg" alt={"phone"} />
          <p>(+961) 3 285 582</p>
        </div>
        {/* <div className={classes.item}>
          <img src={fax} alt={"fax"} />
          <p>{props.info.fax}</p>
        </div> */}
        <div className={classes.item}>
          <img src="/img/icons/svg/email.svg" alt={"email"} />
          <p>info@ghte.com.lb</p>
        </div>
      </div>
    </div>
  );
};

export default ContactUsMap;
