import React from "react";
import classes from "./AboutUsLandingSection.module.css";

const AboutUsLandingSection = () => {
  return (
    <div className={classes.container}>
      <div className={classes.imgCol}>
        <img src="/img/aboutus.jpeg" />
      </div>
      <div className={classes.infoCol}>
        <p>
          <b className={classes.primaryColor}>GHTE</b> is an organization
          specialized in health, social, educational and environmental projects
          in Lebanon and the MENA region.
          <br /> <br /> <br /> We are in a constant aim of improving,
          replicating and connecting experiences in health care system delivery
          in the MENA region.
          <br /> <br /> <br /> We know what it takes to improve health, social, education and environmental standards within healthy habitats.
        </p>
      </div>
    </div>
  );
};

export default AboutUsLandingSection;
