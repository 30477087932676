import React from "react";
import classes from "./ServicesPageMultiTab.module.css";
import { Link } from "react-router-dom";


const ServicesPageMultiTab = (props) => {
  const { active } = props;
  return (
    <div className={classes.container}>
      <div className={classes.menu}>
        {active === "one" ? (
          <Link to="/services/accountabilitylearning" className={classes.link}>
            <h4 className={classes.link + " " + classes.active}>
              Monitoring Evaluation
              Accountability Learning
            </h4>
          </Link>
        ) : (
          <Link to="/services/accountabilitylearning" className={classes.link}>
            <h4 className={classes.link}>
              Monitoring Evaluation
              Accountability Learning
            </h4>
          </Link>
        )}

        {active === "two" ? (
          <Link to="/services/projectmanagement" className={classes.link}>
            <h4 className={classes.link+" "+classes.active}>Project Management</h4>
          </Link>
        ) : (
          <Link to="/services/projectmanagement" className={classes.link}>
            <h4 className={classes.link}>Project Management</h4>
          </Link>
        )}

        {active === "three" ? (
          <Link to="/services/itconsultancy " className={classes.link}>
            <h4 className={classes.link+" "+classes.active}>Information Technology Consultancy </h4>
          </Link>
        ) : (
          <Link to="/services/itconsultancy" className={classes.link}>
            <h4 className={classes.link}>Information Technology Consultancy </h4>
          </Link>
        )}
      </div>
    </div>
  );
};
export default ServicesPageMultiTab;
