import React from "react";
import classes from "./FooterSection.module.css";


//This component renders the footer that container the social media links
/*
props.enableScroll: THis should be true only on the main page where ScrollProgress is active
 */
const FooterSection = () => {

  return (
    <div className={classes.container} >
      
      <div className={classes.column + " " + classes.connect}>
        <h1>Clients & Partners</h1>
        <div className={classes.socials}>
          <img src="/img/partners/amelassociation.png" alt="partner"/>
          <img src="/img/partners/aub.png" alt="partner"/>
          <img src="/img/partners/bi.png" alt="partner"/>
          <img src="/img/partners/himaya.png" alt="partner"/>
          <img src="/img/partners/imc.jpg" alt="partner"/>
          <img src="/img/partners/iocc.png" alt="partner"/>
          <img src="/img/partners/iom.png" alt="partner"/>
          <img src="/img/partners/jica.png" alt="partner"/>
          <img src="/img/partners/lrc.png" alt="partner"/>
          <img src="/img/partners/mdm.png" alt="partner"/>
          <img src="/img/partners/medair.png" alt="partner"/>
          <img src="/img/partners/moph.jpg" alt="partner"/>
          <img src="/img/partners/pui.png" alt="partner"/>
          <img src="/img/partners/relief.png" alt="partner"/>
          <img src="/img/partners/rescue.png" alt="partner"/>
          <img src="/img/partners/unicef.png" alt="partner"/>
        </div>
        <h6>
          Copyright © 2023 <span className={classes.yellow}>GHTE</span>. All
          rights reserved.
        </h6>
      </div>
      
    </div>
  );
};
export default FooterSection;
