import React from "react";
import Button from "../UI/Button/Button";
import MapModule from "./MapModule/MapModule";
import classes from "./MapSection.module.css";

import { Link } from "react-router-dom";

const MapSection = () => {
  return (
    <div className={classes.container}>
      {/* <div className={classes.satelliete}>
        <img className={classes.image} src="/img/lebanonSatellite.webp" />
        <img className={classes.location} src="/img/icons/svg/location.svg" />
      </div> */}
      <div className={classes.mapInfo}>
        <MapModule />
        <Link to="/contactus">
          <Button>Contact Us</Button>
        </Link>
      </div>
    </div>
  );
};
export default MapSection;
