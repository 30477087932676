import React, { useEffect, useState } from "react";
import classes from "./PublicationsListSection.module.css";
import CircularProgress from "@mui/material/CircularProgress";

import axios from "axios";

const PublicationCard = (props) => {
  const { publication } = props;

  const { id, title, description } = publication;

  const getPdf = () => {
    window.open(
      process.env.REACT_APP_API_URL + `/publications/pdf/${id}`,
      "_blank"
    );
  };

  return (
    <div
      className={classes.publicationCard}
      //   onMouseEnter={showInfo}
      //   onMouseLeave={hideInfo}
    >
      <div className={classes.col + " " + classes.info}>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      <div className={classes.col + " " + classes.options}>
        <div className={classes.note}>
          <i>View More</i>
        </div>
        <button className={classes.file} onClick={getPdf}>
          Read More
        </button>
      </div>
    </div>
  );
};

const PublicationsListSection = () => {
  const [publications, setPublications] = useState([]);
  const [sendRequest, setSendRequest] = useState(true);

  useEffect(() => {
    if (sendRequest) {
      axios
        .get(process.env.REACT_APP_API_URL + "/publications")
        .then((response) => {
          // console.log("response", response.data);
          setPublications(response.data);
        })
        .catch((error) => {
          console.error("error", error);
        });
      setSendRequest(false);
    }
  }, [sendRequest]);

  return (
    <div className={classes.container}>
      <h1>Publications:</h1>
      <div className={classes.list}>
        {publications.length === 0 && <CircularProgress   sx={{ color: 'white' }}/>}

        {publications
          .slice(0)
          .reverse()
          .map((publication) => {
            return (
              <PublicationCard
                key={publication.id}
                publication={publication}
                // refresh={refresh}
              />
            );
          })}
      </div>
    </div>
  );
};

export default PublicationsListSection;
