import React from "react";
import Header from "../../Header/Header";
import ServicesPageMultiTab from "../../ServicesPageMultiTab/ServicesPageMultiTab";
import FooterSection from "../../FooterSection/FooterSection";
import ProjectManagementSection from "../../ProjectManagementSection/ProjectManagementSection"
import ITConsultancySection from "../../ITConsultancySection/ITConsultancySection";

const ITConsultancyPage = () => {
  return <div style={{ width: "100%" }}>
    <Header active="Services" />
      <ServicesPageMultiTab active="three"/>
      <ITConsultancySection />
      <FooterSection />
  </div>;
};

export default ITConsultancyPage;
