import React, { useEffect, useRef } from "react";
import classes from "./CareersInfo.module.css";

import { gsap } from "gsap";

const CareersInfo = () => {
  const first = useRef(null);
  const second = useRef(null);
  const third = useRef(null);

  useEffect(() => {
    const animation = {
      duration: 1.5,
      y: 200,
      opacity: 0,
      ease: "ease-out",
    };

    gsap
      .timeline()
      .from(first.current, animation)
      .from(second.current, animation)
      .from(third.current, animation);
  }, []);

  return (
    <div className={classes.container}>
      <h2 ref={first}>
        Are you passionate about health systems policies, reforms and capacity
        building?
      </h2>
      <h2 ref={second}>
        Are you interested in joining a team of experts in the humanitarian and
        health sector to strengthen the Lebanese healthcare system?{" "}
      </h2>
      <h2 ref={third}>If yes, we look forward to hearing from you </h2>
    </div>
  );
};

export default CareersInfo;
