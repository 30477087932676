import React, { useEffect, useRef,useState } from "react";
import classes from "./MonitoringEvaluationSection.module.css";

import { gsap } from "gsap";

const BoxListItem = (props) => {
  const { children, className } = props;


  return <div className={classes.box + " " + className}>
    <li>{children}</li>
  </div>;
};


const MonitoringEvaluationSection = () => {
  const bigCircle = useRef()
  const circles = useRef()
  const circle1 = useRef()
  const circle2 = useRef()
  const circle3 = useRef()

  const topList = useRef()

  useEffect(()=>{

    const circleAnimation = {
      duration:2,
      opacity:0,
      yPercent: 100,
      stagger:0.3,
      ease: "back",
    }
    const circleIdle = {
      duration:5,
      // opacity:0,
      y:-30,
      stagger:2,
      repeat:-1,
      yoyo:true,
      ease: "easeOut",
    }

    const topListAnimation = {
      duration:1,
      opacity:0,
      xPercent: 100,
      // stagger:0.1,
      ease: "back",
    }


    gsap.timeline().from(bigCircle.current,circleAnimation)
    gsap.timeline().from(circles.current.children,circleAnimation)
    gsap.timeline().to(circle1.current,{
      delay: 3,
      duration:5,
      y:-20,
      stagger:2,
      repeat:-1,
      yoyo:true,
      ease: "Power1.easeInOut",
    })
    gsap.timeline().to(circle2.current,{
      delay: 3.2,
      duration:3,
      y:-20,
      stagger:2,
      repeat:-1,
      yoyo:true,
      ease: "Power1.easeInOut",
    })
    gsap.timeline().to(circle3.current,{
      delay: 3.5,
      duration:4,
      y:-20,
      stagger:2,
      repeat:-1,
      yoyo:true,
      ease: "Power1.easeInOut",
    })

    gsap.timeline().from(topList.current.children,topListAnimation)
  },[])

  return (
    <div className={classes.container}>
      <h1>Monitoring Evaluation Accountability Learning</h1>
      {/* ####################################################################### */}

      <div className={classes.row + " " + classes.section1}>
        <div className={classes.col + " " + classes.relative}>
          <p className={classes.bigCircle} ref={bigCircle}>
            GHTE works with local partners, international organizations to
            implement meaningful and system beneficial research to the systems
            and population it serves. We rely on building a multidisciplinary,
            solid and transparent team to work on such projects and generate
            evidence based solutions relevant to the context in terms of outcome
            and impact.
          </p>
          <div className={classes.row + " " + classes.position} ref={circles}>
            <div className={classes.circle} ref={circle1}>
              <img src="/img/icons/svg/training.svg" />
            </div>
            <div className={classes.circle} ref={circle2}>
              <img src="/img/icons/svg/evaluation.svg" />
            </div>
            <div className={classes.circle}  ref={circle3}>
              <img src="/img/icons/svg/research.svg"/>
            </div>
          </div>
        </div>
        <div className={classes.col} ref={topList}>
          <div className={classes.box}>
            <h3>Our Research Services</h3>
            <p>
              Since 2018 GHTE has worked and published research projects,
              articles and commentaries in reputable journals and worked on
              projects across a wide range of sectors but mainly focusing on
              health systems. Our services include:
            </p>
          </div>
          <BoxListItem className={classes.item}>Baselines studies</BoxListItem>
          <BoxListItem className={classes.item}>Midterm Evaluation</BoxListItem>
          <BoxListItem className={classes.item}>Final Evaluation</BoxListItem>
          <BoxListItem className={classes.item}>Impact Evaluation</BoxListItem>
          <BoxListItem className={classes.item}>Implementation research</BoxListItem>
          <BoxListItem className={classes.item}>Quantitative research</BoxListItem>
          <BoxListItem className={classes.item}>Qualitative research</BoxListItem>
          <BoxListItem className={classes.item}>Monitoring frameworks and plans</BoxListItem>
          <BoxListItem className={classes.item}>Capacity building</BoxListItem>
        </div>
      </div>
      <div className={classes.row + " " + classes.section2}>
        <div className={classes.col2}>
          <div className={classes.box+" "+classes.item2}>
            <h3>Vocational skills</h3>
          </div>
          <BoxListItem className={classes.item2}>Infection Prevention and Control in healthcare setting</BoxListItem>
          <BoxListItem className={classes.item2}>Sexual and Reproductive Health</BoxListItem>
          <BoxListItem className={classes.item2}>Gender Based Violence</BoxListItem>
          <BoxListItem className={classes.item2}>Infant and Young Children Feeding</BoxListItem>
          <BoxListItem className={classes.item2}>Malnutrition in children and pregnant women</BoxListItem>
          <BoxListItem className={classes.item2}>Cultural sensitivity in the workplace</BoxListItem>
          <BoxListItem className={classes.item2}>Negotiation and conflict resolution in the workplace</BoxListItem>
          <BoxListItem className={classes.item2}>Monitoring and Evaluation</BoxListItem>
          <BoxListItem className={classes.item2}>Healthy lifestyle management</BoxListItem>
          <BoxListItem className={classes.item2}>Effective Vaccine Management</BoxListItem>
          <BoxListItem className={classes.item2}>Medication Management</BoxListItem>
          <BoxListItem className={classes.item2}>Waste management</BoxListItem>
        
        </div>

        <div className={classes.col2}>
          <div className={classes.box+" "+classes.item2}>
            <h3>Soft skills</h3>
          </div>
          <BoxListItem className={classes.item2}>Effective communication</BoxListItem>
          <BoxListItem className={classes.item2}>Working in teams</BoxListItem>
          <BoxListItem className={classes.item2}>Public speaking skills</BoxListItem>
          <BoxListItem className={classes.item2}>Negotiation and Conflict resolution</BoxListItem>
          <BoxListItem className={classes.item2}>Cultural sensitivity in healthcare</BoxListItem>
          <BoxListItem className={classes.item2}>Problem solving skills</BoxListItem>
          <BoxListItem className={classes.item2}>Time management</BoxListItem>
          <BoxListItem className={classes.item2}>Self-care</BoxListItem>
          <BoxListItem className={classes.item2}>Change management</BoxListItem>
          <BoxListItem className={classes.item2}>Business ethics</BoxListItem>
          <BoxListItem className={classes.item2}>Skill development</BoxListItem>
          <BoxListItem className={classes.item2}>Building your CV</BoxListItem>
        
        </div>

        <div className={classes.col2}>
          <div className={classes.box+" "+classes.item2}>
            <h3>Hard skills</h3>
          </div>
          <BoxListItem className={classes.item2}>Project management</BoxListItem>
          <BoxListItem className={classes.item2}>Accreditation in Primary Healthcare Centers and hospitals</BoxListItem>
          <BoxListItem className={classes.item2}>Strategic planning</BoxListItem>
          <BoxListItem className={classes.item2}>Proposal writing</BoxListItem>
          <BoxListItem className={classes.item2}>Risk management</BoxListItem>
          <BoxListItem className={classes.item2}>Performance management</BoxListItem>
          <BoxListItem className={classes.item2}>Enhancing Immunization Strategies</BoxListItem>
          <BoxListItem className={classes.item2}>ABCs of software development</BoxListItem>

          {/* <div className={classes.smallBoxes}>
            <div className={classes.smallBox}>
              <img src="/img/icons/svg/evaluation.svg"/>
              <h4>IMC evaluation</h4>
            </div>
            <div className={classes.smallBox}>
              <img src="/img/icons/svg/research.svg"/>
              <h4>IMP Research</h4>
            </div>
            <div className={classes.smallBox}>
              <img src="/img/icons/svg/training.svg"/>
              <h4>Training</h4>
            </div>
          </div> */}
        </div>

       
      </div>
    </div>
  );
};

export default MonitoringEvaluationSection;
