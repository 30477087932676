import React, { useRef, useEffect, useState } from "react";
import classes from "./Header.module.css";

import { Link } from "react-router-dom";
import { gsap } from "gsap";

const Header = (props) => {
  const { active } = props;
  
  const nav = useRef();
  const container = useRef();

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(()=>{
    // console.log("width", windowSize)
    if(windowSize.width > 1200){
      nav.current.style.display = "flex"
    }else{
      nav.current.style.display = "none"
    }
  },[windowSize])

  const toggleMenu = () =>{
    
    const display = nav.current.style.display
    // console.log(display)
    if(display === "flex"){
      nav.current.style.display = "none"
    }else{ 
       nav.current.style.display = "flex"
    }
  }

  return (
    <div className={classes.container} ref={container}>
      <Link to={"/"}>
        <img src="/img/logo.webp" className={classes.logo} />
      </Link>
      <div className={classes.menu} onClick={toggleMenu}>
          <img src="/img/icons/svg/menu.svg" alt="icon" className={classes.menuIcon}/>
      </div>
      <div className={classes.nav} ref={nav}>
        {active === "Home" ? (
          <Link className={classes.link + " " + classes.active} to={"/"}>Home</Link>
        ) : (
          <Link className={classes.link} to={"/"}>Home</Link>
        )}
        {active === "About Us" ? (
          <Link className={classes.link + " " + classes.active}  to={"/aboutus"}>About Us</Link>
        ) : (
          <Link className={classes.link} to={"/aboutus"}>About Us</Link>
        )}
        {active === "Services" ? (
          <Link className={classes.link + " " + classes.active} to={"/services/accountabilitylearning"}>Services</Link>
        ) : (
          <Link className={classes.link} to={"/services/accountabilitylearning"}>Services</Link>
        )}
        {active === "Publications" ? (
          <Link className={classes.link + " " + classes.active} to={"/publications"}>Publications</Link>
        ) : (
          <Link className={classes.link} to={"/publications"}>Publications</Link>
        )}
        {active === "Gallery" ? (
          <Link className={classes.link + " " + classes.active} to={"/gallery"}>Gallery</Link>
        ) : (
          <Link className={classes.link} to={"/gallery"}>Gallery</Link>
        )}
        {active === "Careers" ? (
          <Link className={classes.link + " " + classes.active} to={"/careers"}>Careers</Link>
        ) : (
          <Link className={classes.link} to={"/careers"}>Careers</Link>
        )}
        {active === "Contact Us" ? (
          <Link className={classes.link + " " + classes.active} to={"/contactus"}>Contact Us</Link>
        ) : (
          <Link className={classes.link} to={"/contactus"}>Contact Us</Link>
        )}
      </div>
    </div>
  );
};
export default Header;
