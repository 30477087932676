import React from 'react'

import Header from '../Header/Header'
import FooterSection from '../FooterSection/FooterSection'
import AboutUsLandingSection from '../AboutUsLandingSection/AboutUsLandingSection'
import CEOMsgSection from "../CEOMsgSection/CEOMsgSection"
import AboutUsInfoSection from '../AboutUsInfoSection/AboutUsInfoSection'
import Space from '../UI/Space/Space'
import OurStructureSection from '../OurStructureSection/OurStructureSection'

const AboutUsPage = () =>{

    return <div style={{width:"100%"}}>
        <Header active="About Us"/>
        <AboutUsLandingSection />
        <CEOMsgSection />
        <Space height={"300px"} />
        <AboutUsInfoSection />
        <OurStructureSection />
        <FooterSection />
    </div>
}
export default AboutUsPage