import React, { useCallback, useEffect, useRef } from "react";
import { gsap } from "gsap";
import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from "reactflow";

import "reactflow/dist/style.css";
import "./FlowDiagram.css";

const edgeStyle ={ 
  stroke: 'white',
  opacity: 2,
}

const initialNodes = [
  { id: "1", position: { x: 250, y: 50 }, data: { label: "CEO" } },
  {
    id: "2",
    position: { x: 410, y: 50 },
    data: { label: "Board of Directors" },
  },

  { id: "3", position: { x: 180, y: 150 }, data: { label: "MEAL Unit" } },

  { id: "6", position: { x: 50, y: 250 }, data: { label: "Research Monitoring / Unit" } },
  { id: "7", position: { x: 50, y: 350 }, data: { label: "Team Leaders / Evaluators" } },
  { id: "8", position: { x: 50, y: 450 }, data: { label: "Research Associates" } },
  { id: "9", position: { x: 50, y: 550 }, data: { label: "Research Assistants" } },
  { id: "10", position: { x: 50, y: 650 }, data: { label: "Enumerators" } },

  { id: "11", position: { x: 315, y: 250 }, data: { label: "Capacity Building" } },
  { id: "12", position: { x: 225, y: 350 }, data: { label: "Trainers" } },
  { id: "13", position: { x: 225, y: 450 }, data: { label: "Facilitators" } },
  { id: "14", position: { x: 400, y: 350 }, data: { label: "Product Developers" } },


  { id: "4", position: { x: 575, y: 150 }, data: { label: "Project Management" } },
  { id: "15", position: { x: 575, y: 250 }, data: { label: "Strategic Planners" } },
  { id: "16", position: { x: 575, y: 350 }, data: { label: "Specialists / Consultants" } },
  { id: "17", position: { x: 575, y: 450 }, data: { label: "Information management officers" } },


  { id: "5", position: { x: 750, y: 150 }, data: { label: "Administration" } },
  { id: "18", position: { x: 750, y: 250 }, data: { label: "Finance / Administration Manager" } },
  { id: "19", position: { x: 750, y: 350 }, data: { label: "Administrative Coordinator" } },
  { id: "20", position: { x: 750, y: 450 }, data: { label: "Logistics Coordinator" } },

];
const initialEdges = [
  // { id: "e1-3", source: "1", target: "3", type: "smoothstep" },
  // { id: "e1-4", source: "1", target: "4", type: "smoothstep" },
  // { id: "e1-5", source: "1", target: "5", type: "smoothstep" },
  { id: "e2-3", source: "2", target: "3", type: "smoothstep",style: edgeStyle },
  { id: "e2-4", source: "2", target: "4", type: "smoothstep",style: edgeStyle },
  { id: "e2-5", source: "2", target: "5", type: "smoothstep",style: edgeStyle },
  { id: "e3-6", source: "3", target: "6", type: "smoothstep",style: edgeStyle },
  { id: "e6-7", source: "6", target: "7", type: "smoothstep",style: edgeStyle },
  { id: "e7-8", source: "7", target: "8", type: "smoothstep",style: edgeStyle },
  { id: "e8-9", source: "8", target: "9", type: "smoothstep",style: edgeStyle },
  { id: "e9-10", source: "9", target: "10", type: "smoothstep",style: edgeStyle },

  { id: "e3-11", source: "3", target: "11", type: "smoothstep",style: edgeStyle },
  { id: "e11-12", source: "11", target: "12", type: "smoothstep",style: edgeStyle },
  { id: "e11-14", source: "11", target: "14", type: "smoothstep",style: edgeStyle },
  { id: "e12-13", source: "12", target: "13", type: "smoothstep",style: edgeStyle },

  { id: "e4-15", source: "4", target: "15", type: "smoothstep",style:edgeStyle },
  { id: "e15-16", source: "15", target: "16", type: "smoothstep",style:edgeStyle },
  { id: "e16-17", source: "16", target: "17", type: "smoothstep",style: edgeStyle },

  { id: "e5-18", source: "5", target: "18", type: "smoothstep",style: edgeStyle },
  { id: "e18-19", source: "18", target: "19", type: "smoothstep",style:edgeStyle },
  { id: "e19-20", source: "19", target: "20", type: "smoothstep",style: edgeStyle },


];

export default function App() {
  const el = useRef()
  const q = gsap.utils.selector(el);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

    // useEffect(()=>{
    //   gsap.from(q(".react-flow__node"), {
    //     duration: 1,
    //     stagger:0.1,
    //     yPercent: 100,
    //     opacity:0,
    //   })
    // },[])

  return (
    <div className="container" ref={el}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        fitView
        // onNodesChange={onNodesChange}
        // onEdgesChange={onEdgesChange}
        // onConnect={onConnect}
      >
        <Controls />
        {/* <MiniMap /> */}
        <Background variant="dots" gap={12} size={1} color="transparent" />
      </ReactFlow>
    </div>
  );
}
