import React from "react";
import Button from "../UI/Button/Button";
import classes from "./AboutUsSection.module.css";

import { Link } from "react-router-dom";
import PulsingSparkle from "../UI/PulsingSparkle/PulsingSparkle";

const AboutUsSection = () => {
  return (
    <div className={classes.container}>
      <div className={classes.col}>
        <img src="/img/logo.webp" className={classes.logo} />
        <div className={classes.gradientBorder}>
          <div className={classes.white}>
            <p>
              As of November 1st 2023, <b className={classes.primaryColor}>GHTE</b> holds a Memorandum of Understanding
              with New York University's School of Global Public Health.
            </p>
          </div>
          <PulsingSparkle className={classes.circle1} delay={0} duration={0.5} scale={1.5} />
          <PulsingSparkle className={classes.circle2} delay={0.1} duration={0.6} scale={0.8} />
          <PulsingSparkle className={classes.circle3} delay={0.2} duration={0.4} scale={1.2} />
          <PulsingSparkle className={classes.circle4} delay={0.1} duration={0.7} scale={0.7}/>
          <PulsingSparkle className={classes.circle5+" "+classes.hoverCircles} delay={0.1} duration={0.2} scale={0.7}/>
          <PulsingSparkle className={classes.circle6+" "+classes.hoverCircles} delay={0.2} duration={0.4} scale={0.9}/>
          <PulsingSparkle className={classes.circle7+" "+classes.hoverCircles} delay={0.3} duration={0.6} scale={1.3}/>
          <PulsingSparkle className={classes.circle8+" "+classes.hoverCircles} delay={0} duration={0.8} scale={1}/>
          <PulsingSparkle className={classes.circle9+" "+classes.hoverCircles} delay={0.3} duration={0.6} scale={1.1}/>
        </div>
      </div>
      <div className={classes.col}>
        <h1>About Us</h1>
        <p>
          <b className={classes.primaryColor}>GHTE</b> is an organization
          specialized in health, social, educational and environmental projects
          in Lebanon and the MENA region.
          <br /> <br /> <br /> We are in a constant aim of improving,
          replicating and connecting experiences in health care system delivery
          in the MENA region.
          <br /> <br /> <br /> We know what it takes to improve health, social,
          education and environmental standards within healthy habitats.
        </p>
        <Link to="/aboutus">
          <Button className={classes.btn}>Read More</Button>
        </Link>
      </div>
      
    </div>
  );
};
export default AboutUsSection;
