import React from 'react'
import classes from "./CareersSection.module.css"
import CareersInfo from './CareersInfo/CareersInfo'
import CareersForm from './CareersForm/CareersForm'

const CareersSection = () => {
  return (
    <div className={classes.container}>
      <CareersInfo />
      <CareersForm />
    </div>
  )
}

export default CareersSection
