import React, { useRef, useEffect } from "react";
import classes from "./AboutUsInfoSection.module.css";

import { gsap } from "gsap";

const AboutUsInfoSection = () => {
  const one = useRef();
  const two = useRef();
  const three = useRef();
  const mission = useRef();

  useEffect(() => {

    gsap.to(one.current, {
      scrollTrigger:{
        trigger: one.current,
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: () => "top 20%",
        end: () => "+=900px",
        // markers: true,
      },
    });
   
    // ##############################################################

    gsap.to(two.current, {
      scrollTrigger:{
        trigger: two.current,
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: () => "top 20%",
        end: () => "+=900px",
        // markers: true,
      },
    });
    // ##############################################################

    gsap.to(three.current, {
      scrollTrigger:{
        trigger: three.current,
        pin: true,
        pinSpacing: true,
        scrub: true,
        start: () => "top 20%",
        end: () => "+=900px",
        // markers: true,
      },
    });

    gsap.to(mission.current,{
      
      // duration: 0.1,
      yPercent: -900,
      ease: "back.in(2)",
      scrollTrigger:{
        trigger: mission.current,
        // pin: true,
        // pinSpacing: true,
        scrub: true,
        start: () => "top 20%",
        end: () => "+=600px",
        // markers: true,
      },
    })


  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.row}  ref={one}>
        <div className={classes.box + " " + classes.colW}>
          <h1>WHO WE ARE</h1>
          <p>
            Experts in public health, Social and environmental sciences,
            Evaluation and implementation.
            <br />
            <br />
            We are in a constant aim of improving, replicating and connecting
            experiences in health care system delivery in the MENA region.
            <br />
            <br /> We know what it takes to improve health, social, education
            and environmental standards within healthy habitats.
          </p>
        </div>
        <div className={classes.colW}>
          <img className={classes.icon} src="/img/icons/svg/who.svg" />
        </div>
      </div>
      {/* ############################################################################## */}
      <div className={classes.row} ref={two}>
        <div className={classes.colW}>
          <img className={classes.icon} src="/img/icons/svg/todo.svg" />
        </div>
        <div className={classes.box + " " + classes.colW}>
          <h1>WHAT WE DO</h1>
          <p>
            We design, execute, monitor and improve health, social, and
            environmental projects while ensuring sustainable knowledge and
            technology transfer.
          </p>
        </div>
      </div>
      {/* ############################################################################## */}
      <div className={classes.row} ref={three}>
        <div className={classes.box + " " + classes.colW}>
          <h1>Mission</h1>
          <p>
            <b className={classes.primaryColor}>GHTE</b>’s mission is to support
            national and international authorities and organizations in skill
            development, evidence generation and system strengthening in the
            social, environment and health fields.
          </p>
        </div>
        <div className={classes.colW}>
          <img className={classes.icon} src="/img/icons/svg/mission.svg" ref={mission}/>
        </div>
      </div>
    </div>
  );
};

export default AboutUsInfoSection;
