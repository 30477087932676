import React from 'react'

import Header from '../Header/Header'
import Landing from '../Landing/Landing'
import AboutUsSection from '../AboutUsSection/AboutUsSection'
import ServicesSection from '../ServicesSection/ServicesSection'
import CEOMsgSection from '../CEOMsgSection/CEOMsgSection'
import MapSection from '../MapSection/MapSection'
import FooterSection from '../FooterSection/FooterSection'

const HomePage = () =>{

    return <div style={{width:"100%"}}>
        <Header active="Home"/>
        <Landing />
        <AboutUsSection />
        <ServicesSection />
        <CEOMsgSection />
        <MapSection />
        <FooterSection />
    </div>
}
export default HomePage