import React, { useEffect, useRef, useState } from "react";
import classes from "./CEOMsgSection.module.css";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";

import CEOProfile from "./CEOProfile/CEOProfile";

const CEOMsgSection = () => {
  const msgContainer = useRef();
  const [quote1, setQuote1] = useState("");
  const [quote2, setQuote2] = useState("");
  const [quote3, setQuote3] = useState("");
  const [quote4, setQuote4] = useState("");

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.from(msgContainer.current, {
      duration: 0.6,
      scale: 0.2,
      xPercent: -50,
      yPercent: -5,
      opacity: 0,
      ease: "back",

      scrollTrigger: {
        trigger: msgContainer.current,
        toggleActions: "restart pause resume reverse",
        // onLeaveBack: "reset",
        start: "top 75%",
        // end: "top 50% ",
        // markers: true,
        // scrub: 1,
      },
      onComplete: () => {
        type1();
      },
    });
  }, []);

  // variables manage the typing animation
  var i = 0;
  var j=0;
  var k=0;
  var l=0;
  var txt1 =
    "I am deeply proud to be part of a group of people who stepped in, and took action in a collapsing environment in Lebanon."; // quote to type
  var txt2 =
    "When chaos is prevailing and basic humanity is jeopardized, when no one is in charge and everyone retreats to a safe heaven, members of GHTE, individually and collaboratively took responsibility, and ventured into a rescue mission of the Lebanese Primary Healthcare Sector in Lebanon.";
  
  var txt3 = "It takes more than knowledge, education and high IQ to prevail. It takes at least, a set of values, that find their roots deep in the basic universal human nature with a complex survival methodology that we all who believe in humanity and science, should learn and preach for a better world."

  var txt4 = "GHTE is about taking responsibility, stepping in, leading and delivering."

  var speed = 20; //speed in ms of typing (increase to slow the typing animation)

  const type1 = () => {
    //repeats until the whole message is typed
    if (i <= txt1.length) {
      setQuote1(txt1.substring(0, i));
      i++;
      setTimeout(type1, speed);
    } else{
      type2()
    }
  };
  const type2 = () => {
    
    // console.log("type 2",(j <= txt2.length))
    //repeats until the whole message is typed
    if (j <= txt2.length) {
      setQuote2(txt2.substring(0, j));
      j++;
      setTimeout(type2, speed);
    } else {
      type3()
    }
  };
  const type3 = () => {
    //repeats until the whole message is typed
    if (k <= txt3.length) {
      setQuote3(txt3.substring(0, k));
      k++;
      setTimeout(type3, speed);
    }else{
      type4()
    }
  };
  const type4 = () => {
    //repeats until the whole message is typed
    if (l <= txt4.length) {
      setQuote4(txt4.substring(0, l));
      l++;
      setTimeout(type4, speed);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.col}>
        <CEOProfile className={classes.profile} />
        <h4>Maan F Hamade</h4>
        <h4>CEO</h4>
      </div>

      <div className={classes.msgContainer} ref={msgContainer}>
        <div className={classes.tri}></div>
        <div className={classes.msg}>
          <p>
            {quote1}
            <br />
            <br />
            {quote2}
            <br />
            <br />
            {quote3}
            <br />
            <br />
            {quote4}
          </p>
        </div>
      </div>
    </div>
  );
};
export default CEOMsgSection;
