import classes from "./App.module.css";

import HomePage from "./components/pages/HomePage";

import { Route, Routes } from "react-router-dom";
import AboutUsPage from "./components/pages/AboutUsPage";

import MonitoringEvaluationPage from "./components/pages/ServicesPages/MonitoringEvaluationPage";
import ProjectManagementPage from "./components/pages/ServicesPages/ProjectManagementPage";
import ITConsultancyPage from "./components/pages/ServicesPages/ITConsultancyPage";
import PublicationsPage from "./components/pages/PublicationsPage";
import GalleryPage from "./components/pages/GalleryPage";
import CareersPage from "./components/pages/CareersPage";
import ContactUsPage from "./components/pages/ContactUsPage";
import CollectionViewPage from "./components/pages/CollectionViewPage";

// require('dotenv').config()


function App() {
  return (
    <div className={classes.App}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />

        <Route
          path="/services/accountabilitylearning"
          element={<MonitoringEvaluationPage />}
        />
        <Route
          path="/services/projectmanagement"
          element={<ProjectManagementPage />}
        />
        <Route
          path="/services/itconsultancy"
          element={<ITConsultancyPage />}
        />
        <Route path="/publications" element={<PublicationsPage/>} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/collection" element={<CollectionViewPage />} />
        <Route path="/careers" element={<CareersPage />} />
        <Route path="/contactus" element={<ContactUsPage />} />
      </Routes>
    </div>
  );
}

export default App;
